import { GET } from '@/admin-shared-modules/utils/ajax'
import { ref } from 'vue'
import { App } from './store'

// https://jwxqsdy2wiv.feishu.cn/docx/VlMRd2nxQoNS1TxpsO4c2o4Rn2d
export const BuildInAPPs: App[] = [
  {
    type: 'libResource',
    name: '校本图书',
    icon: require('./assets/校本图书.png'),
    libResourceKey: 'home'
  },
  {
    type: 'libResource',
    name: '书单',
    icon: require('./assets/书单.png'),
    libResourceKey: 'topic'
  },
  {
    type: 'libResource',
    name: '图书',
    icon: require('./assets/图书.png'),
    libResourceKey: 'book'
  },
  {
    type: 'libResource',
    name: '期刊',
    icon: require('./assets/期刊.png'),
    libResourceKey: 'magazine'
  },
  {
    type: 'libResource',
    name: '分级阅读',
    icon: require('./assets/分级阅读.png'),
    libResourceKey: 'eduStudent'
  },
  {
    type: 'libResource',
    name: '校园阅读教师阅读',
    icon: require('./assets/图书.png'),
    libResourceKey: 'eduTeacher'
  },
  {
    type: 'libResource',
    name: '校园阅读家长阅读',
    icon: require('./assets/图书.png'),
    libResourceKey: 'eduParents'
  },
  {
    type: 'libResource',
    name: '校园阅读主题书单',
    icon: require('./assets/图书.png'),
    libResourceKey: 'eduTopic'
  },
  {
    type: 'libResource',
    name: '有声书屋',
    icon: require('./assets/有声书屋.png'),
    libResourceKey: 'audio'
  },
  {
    type: 'libResource',
    name: '阅读测评',
    icon: require('./assets/阅读测评.png'),
    libResourceKey: 'testing'
  },

  // 功能应用
  {
    type: 'libApp',
    name: '阅读计划',
    icon: require('./assets/阅读计划.png'),
    libAppKey: 7
  },
  {
    type: 'libApp',
    name: '课程',
    icon: require('./assets/课程.png'),
    libAppKey: 4
  },
  {
    type: 'libApp',
    name: '消息',
    icon: require('./assets/消息.png'),
    libAppKey: 5
  },
  {
    type: 'libApp',
    name: '学生报告',
    icon: require('./assets/阅读报告.png'),
    libAppKey: 9
  },

  {
    type: 'libApp',
    name: '会议记录',
    icon: require('./assets/读书笔记.png'),
    libAppKey: 101
  },
  {
    type: 'libApp',
    name: '随手记',
    icon: require('./assets/读书笔记.png'),
    libAppKey: 102
  },
  {
    type: 'libApp',
    name: '练字本',
    icon: require('./assets/练字本.png'),
    libAppKey: 103
  },
  {
    type: 'libApp',
    name: 'OCR识别',
    icon: require('./assets/OCR识别.png'),
    libAppKey: 104
  },
  {
    type: 'libApp',
    name: '词典',
    icon: require('./assets/词典.png'),
    libAppKey: 105
  },
  {
    type: 'libApp',
    name: '文件浏览',
    icon: require('./assets/文件浏览.png'),
    libAppKey: 106
  },
  {
    type: 'libApp',
    name: '系统设置',
    icon: require('./assets/系统设置.png'),
    libAppKey: 107
  },
  {
    type: 'libApp',
    name: '个人中心',
    icon: require('./assets/个人中心.png'),
    libAppKey: 108
  },
  {
    type: 'libApp',
    name: '全部应用',
    icon: require('./assets/本地应用.jpg'),
    libAppKey: 109
  }
]

export const useCustomApps = () => {
  const data = ref<App[]>()
  const loading = ref(true)

  async function fetch() {
    loading.value = true
    const res = await GET('device/config/link/list', {
      data: {
        pageSize: 99
      }
    })
    data.value = res.data.items
    loading.value = false
  }

  return {
    loading,
    data,
    fetch
  }
}
const apps: App[] = [
  {
    type: 'web',
    name: 'web',
    icon: '//placehold.it/200x200',
    url: 'https://www.baidu.com'
  },
  {
    type: 'apk',
    icon: '//placehold.it/200x200',
    name: 'apk',
    url: 'https://www.baidu.com'
  }
]
